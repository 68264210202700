html, body{
    height: 100% !important;
    width: 100%;
}

.root {
    height: 100vh;
    width: 100%;
    background-color:#5286F3 !important;
}

.simple-login-container{
    width:350px;
    max-width:100%;
    margin:50px auto;
    background-color: #fff;
    border-style: solid;
    border-radius: 5px;
    border-width: 0px;
}
.simple-login-container h2{
    text-align:center;
    font-size:20px;
}

.simple-login-container .btn-login{
    background-color:#FF5964;
    color:#fff;
}
a{
    color:#fff;
}
.error-message {
    color: red
}