html, body {
  height: 100%;
  background-color: white !important
}

body {
  overflow-x: hidden;
}

.dtr-details {
  list-style: none;
}

.dtr-details li span {
  width: 130px;
}

.table-footer-text {
  font-size: 12px !important;
}

.loading-container {
  height: 93vh !important;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.btn-link:hover {
  cursor: pointer;
  text-decoration: underline !important;
}

.collapsed td:first-child::before {
  font-family: "Font Awesome 5 Free";
  content: "\f078";
  font-size: 12px;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 600;
}

.child::before {
  display: none;
}

.dtr-title {
  font-weight: bold;
  margin-right: 15px;
}

.dtr-title::after {
  content: ':';
}

.btn-neutral {
  border-width: 0;
  background: transparent;
}

.btn-call-filter {
  outline: none !important;
  border-width: 1px;
  border-radius: 10px;
  border-color: #007bff;
}

.btn-call-filter span {
  color: #007bff;
}

.btn-call-filter.active {
  background-color: #007bff;
}

.btn-call-filter.active span {
  color: white;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}