.DataTables_Table_0_length{
    display: inline-block;
}
.dataTables_length {

}
.dataTables_length label {
    display: inline-block;
}
.dataTables_length label select {
    width: auto
}

.dataTables_filter label {
    display: flex
}

.dataTables_filter label input{
    margin-left: 10px;
}

.action-icon {
    color: #007bff;
    margin-right: 5px;
}

.action-icon:hover{
    color: #024ea0
}

.action-button {
    display: inline-block;
}

td {
    white-space: nowrap !important
}