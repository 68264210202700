.impact_container {
    font-size: 14px;
}

.impact_container table {
    width: 100%;
}

.impact_container .data-table thead th {
    text-align: center;
    font-size: 12px;
}

@media print{@page {size: landscape}}