.close-icon {
    color: #5c5c5c
}

.close-icon:hover {
    color: black;
    cursor: pointer
}
.editor-container{
    width: 100%;
    max-width: 680px;
}
.modal-header {
    min-width: 60vh;
}

.custom-modal-body {
    max-height: 80vh;
    overflow: scroll;
}

.custom-modal-footer {
    
}

.react-datepicker-wrapper {
    width: 100%
}